<template>
  <v-container fluid>
    <!-- ヘッダー -->
    <v-row dense>
      <v-col cols="6" sm="2" md="1" lg="1" xl="1">
        <div align="left">
          <v-btn color="grey" small class="black--text" @click="toBack">前の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="6" sm="2" md="1" lg="1" xl="1">
        <div align="right">
          <v-btn color="grey" small class="black--text" @click="toForward">次の日へ</v-btn>
        </div>
      </v-col>
      <v-col cols="12" sm="8" md="10" lg="10" xl="10">
        <v-card class="mx-auto text-center black--text" outlined>
          <v-row dense>
            <v-col cols="8" sm="8" md="10" lg="10" xl="10">
              <div align="center">
                {{ yyyymmddDisp }}の出欠連絡
              </div>
            </v-col>
            <v-col cols="4" sm="4" md="2" lg="2" xl="2">
              <div align="right">
                <v-btn color="grey" small class="black--text" @click="calendar">日付選択</v-btn>

              </div>
            </v-col>
          </v-row>
          <Datepicker
            ref="picker"
            :monday-first="true"
            wrapper-class="calender-text-box-wrapper"
            input-class="calender-text-box"
            placeholder=""
            :language="language"
            @selected=dateSelected />
        </v-card>
      </v-col>
    </v-row>
    
    <!-- 検索条件 and 並び替え条件(モバイル用) -->
    <v-row dense class="d-sm-none">
      <v-col cols="6" class="d-flex justify-start">
        <div v-if="cancelCount > 0">
          出席：{{ cancelCount }}人
        </div>
      </v-col>
      <v-col cols="6" class="d-flex justify-end pr-2">
        <v-btn color="green" @click="filterDialog1 = true" small>
          <v-icon right dark v-if="isFilterFlg1">mdi-filter</v-icon>
          <v-icon right dark v-if="!isFilterFlg1">mdi-filter-outline</v-icon>
          フィルタ
        </v-btn>
      </v-col>
    </v-row>
    
    <!-- 検索条件 and 並び替え条件(PC用) -->
    <v-row dense class="hidden-xs-only">
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <!-- グループフィルタ -->
        <v-select
          v-model="groupFilterValue"
          label="グループ指定"
          :items="groupList"
          item-text="groupName"
          item-value="filterItem"
          outlined
          dense
          clearable
          multiple
          chips
          deletable-chips
          hide-details="auto"
          background-color="white"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <!-- 出欠区分フィルタ -->
        <v-select
          v-model="syukketuKbnFilterValue"
          label="出欠区分指定"
          :items="syukketuKbnList"
          outlined
          dense
          multiple
          chips
          deletable-chips
          hide-details="auto"
          background-color="white"
        ></v-select>
      </v-col>
      
      <v-col cols="0" sm="3" md="3" lg="3" xl="3" class="d-flex justify-start">
        <div v-if="cancelCount > 0">
          出席：{{ cancelCount }}人
        </div>
      </v-col>

      <v-col cols="12" sm="3" md="3" lg="3" xl="3">
        <v-select
          label="並び順"
          :items="sortKbnList"
          item-text="text"
          item-value="value"
          v-model="sortKbn"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        ></v-select>
      </v-col>
    </v-row>

    <!-- 明細表示 -->
    <v-row v-resize="updHeight">
      <v-col cols="12" justify="center">
        <v-data-table
          :headers="headers"
          :items="members"
          :items-per-page="-1"
          :footer-props="{'disable-items-per-page': true}"
          dense
          disable-sort
          :height="tableHeight"
          :fixed-header="true"
          :loading="isListLoding" loading-text="読込中"
          mobile-breakpoint="0"
        >
          <!-- ラベル：最終更新日時 -->
          <template v-slot:top>
            <v-row dense>
              <v-col cols="12" class="d-flex text-caption justify-end">
                <div>最終更新時間：{{ lastReloadTime }}(15分毎自動更新)</div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </template>
          <!-- ラベル：生徒氏名＋保護者連絡日時＋登録者名 -->
          <template #[`header.name`]="{}"> 
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                生徒氏名
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                保護者連絡日時
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                登録者名
              </v-col>
            </v-row>
          </template>
          <!-- ラベル：KBK学年学級 -->
          <template #[`header.filterItem`]="{}"> 
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                課程/部/科
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                学年/学級/出席番号
              </v-col>
            </v-row>
          </template>

          <!-- 生徒氏名と保護者連絡日時とモバイル用まとめセル -->
          <template #[`item.name`]="{ item }">
            <v-row no-gutters class="hidden-xs-only">
              <v-col cols="12" justify="center">
              <v-tooltip right v-if="item.mailAddressAuthFlg">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-2" v-bind="attrs" v-on="on">mdi-email-outline</v-icon>
                </template>
                <span>メールアドレス設定済</span>
              </v-tooltip>
              <v-tooltip right v-if="!item.mailAddressAuthFlg">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="ml-2" v-bind="attrs" v-on="on">mdi-phone</v-icon>
                </template>
                <span>メールアドレス未設定</span>
              </v-tooltip>
              {{ item.name }}
              </v-col>
            </v-row>
            <v-row no-gutters class="hidden-xs-only">
              <v-col cols="12" justify="center">
              {{ item.parentUpdDate }}
              </v-col>
            </v-row>
            <v-row no-gutters class="hidden-xs-only">
              <v-col cols="12" justify="center">
                <span v-if="item.createUserFlg" class="text-caption red--text">
                  {{ item.createUserNm }}
                </span>
                <span v-if="!item.createUserFlg" class="text-caption black--text">
                  {{ item.createUserNm }}
                </span>
              </v-col>
            </v-row>

            <!-- モバイル用まとめ -->
            <v-row dense class="d-sm-none">
              <v-col cols="12" justify="center">
                <!-- １行目（生徒氏名＋KBK学年学級出席番号） -->
                <v-row no-gutters>
                  <v-col cols="5" class="d-flex text-subtitle-1">
                    {{ item.name }}
                  </v-col>
                  <v-col cols="7" class="d-flex justify-end text-caption">
                    {{ item.buNm }}/{{ item.kaNm }}/{{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}
                  </v-col>
                </v-row>
                <!-- ２行目（通用門通過時刻＋出欠区分＋保護者更新日時） -->
                <v-row no-gutters>
                  <v-col cols="4" class="d-flex text-subtitle-1">
                    <span v-if="item.gatePassageTime">{{ item.gatePassageTime }}：</span>{{ item.syukketuNm }}
                  </v-col>
                  <v-col cols="8" class="d-flex justify-end text-caption">
                    保護者更新：{{ item.parentUpdDate }}
                  </v-col>
                </v-row>
                <!-- ３行目（備考） -->
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex text-caption">
                    {{ item.biko }}
                  </v-col>
                </v-row>
                <!-- ４行目（登録者） -->
                <v-row no-gutters>
                  <v-col cols="12" class="d-flex text-caption">
                    登録者：
                    <span v-if="item.createUserFlg" class="text-caption red--text">
                      {{ item.createUserNm }}
                    </span>
                    <span v-if="!item.createUserFlg" class="text-caption black--text">
                      {{ item.createUserNm }}
                    </span>
                  </v-col>
                </v-row>
                <!-- ５行目（ボタン類） -->
                <v-row no-gutters>
                  <v-col cols="2" class="d-flex text-caption">
                    <v-btn color="accent" small class="black--text" @click="onClickReadItem(item)" :disabled="lockConfirmButton(item)">確認</v-btn>
                  </v-col>
                  <v-col cols="3" class="d-flex text-caption">
                    <div v-if="item.readSchoolFlg">確認済</div>
                    <div v-else>未確認</div>
                  </v-col>
                  <v-col cols="2" class="d-flex text-caption">
                    <v-btn color="accent" small class="black--text" @click="onClickEditItem(item)" :disabled="item.pastLockFlg">返信</v-btn>
                  </v-col>
                  <v-col cols="2" class="d-flex text-caption">
                    <v-btn color="accent" small class="black--text" @click="onClickApprovalItem(item)" :disabled="item.pastLockFlg">確定</v-btn>
                  </v-col>
                  <v-col cols="3" class="d-flex text-caption">
                    <v-icon class="ml-2" v-if="item.approvalFlg">mdi-check-outline</v-icon>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
                </template>

          <!-- KBK学年学級 -->
          <template #[`item.filterItem`]="{ item }">
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                {{ item.kateiNm }}/{{ item.buNm }}/{{ item.kaNm }}
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" justify="center">
                {{ item.gakunen }}/{{ item.gakkyu }}/{{ item.syussekiNo }}
              </v-col>
            </v-row>
          </template>

          <!-- 出欠確認 -->
          <template #[`item.readSchoolFlg`]="{ item }">
            <div v-if="item.readSchoolFlg">確認済</div>
            <div v-else>未確認</div>
          </template>

          <!-- 出欠区分名 -->
          <template #[`item.syukketuKbn`]="{ item }">
            {{ item.syukketuNm }}
          </template>

          <!-- 確認ボタン -->
          <template #[`item.action0`]="{ item }">
            <v-btn color="accent" class="black--text" @click="onClickReadItem(item)" :disabled="lockConfirmButton(item)">確認</v-btn>
          </template>

          <!-- 返信ボタン -->
          <template #[`item.action1`]="{ item }">
            <v-btn color="accent" class="black--text" @click="onClickEditItem(item)" :disabled="item.pastLockFlg">返信</v-btn>
          </template>

          <!-- 確定状況 -->
          <template #[`item.approvalFlg`]="{ item }">
            <v-icon class="ml-2" v-if="item.approvalFlg">mdi-check-outline</v-icon>
          </template>

          <!-- 確定ボタン -->
          <template #[`item.action2`]="{ item }">
            <v-btn color="accent" class="black--text" @click="onClickApprovalItem(item)" :disabled="item.pastLockFlg">確定</v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn v-bind="attrs" v-on="on" fixed fab bottom color="secondary" @click="reload" style="bottom: 50px">
          <v-icon color="white">mdi-refresh</v-icon>
        </v-btn>
      </template>
      <span>再読込</span>
    </v-tooltip>

    <!-- 出欠確定モーダル -->
    <v-dialog
      v-model="attendanceApprovalDialog"
      max-width="500"
      persistent
    >
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-card
          class="d-flex justify-center flex-column mx-auto flat"
          width="500"
          color="#fff"
        >
          <v-card-title class="d-flex justify-center pa-0 mt-6" white-space="pre">
            <p>出欠確定({{ approvalStudentNm }})</p>
          </v-card-title>

          <v-card-text class="d-flex justify-center flex-column">
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider
                  name="確定出欠"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                      label="確定出欠"
                      item-text="name"
                      item-value="code"
                      v-model="selectedMain"
                      prepend-icon="$cet_required"
                      :items="selectMain"
                      :error-messages="errors[0]"
                      outlined
                      @change="changeMain"
                      hide-details="auto"
                    ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider
                  name="確定理由"
                  rules="required"
                  v-slot="{ errors }"
                  :skipIfEmpty="false"
                >
                  <v-select
                      label="確定理由"
                      item-text="name"
                      item-value="code"
                      v-model="approvalKbn"
                      prepend-icon="$cet_required"
                      :items="selectSubFilter"
                      :error-messages="errors[0]"
                      outlined
                      @change="changeSub"
                      hide-details="auto"
                    ></v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col dense cols="12" justify="center">
                <ValidationProvider v-if="approvalKbn.endsWith('99')" name="その他理由" rules="required|max:500" v-slot="{ errors }">
                  <v-text-field
                    v-model="approvalText"
                    label="その他理由"
                    placeholder="その他理由"
                    prepend-icon="$cet_required"
                    :error-messages="errors[0]"
                    outlined
                    dense
                    :counter="500"
                    hide-details="auto"
                    v-if="approvalKbn.endsWith('99')"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn color="grey" class="black--text" @click="attendanceApprovalDialog = false" width="49%">
              閉じる
            </v-btn>
            <v-btn color="accent" class="black--text" @click="syukketuApprovalSave()" :disabled="invalid" width="49%">
              確定
            </v-btn>
          </v-card-actions>
        </v-card>
      </ValidationObserver>
    </v-dialog>

    <!-- フィルタモーダル -->
    <v-dialog
      v-model="filterDialog1"
      persistent
      max-width="600"
    >
      <v-card
        class="d-flex justify-center flex-column mx-auto flat"
        width="600"
        color="#fff"
      >
        <v-card-title class="d-flex pt-3 justify-center" white-space="pre">
          <v-row dense>
            <v-col dense cols="4" justify="center">
              <v-btn color="grey" class="black--text" @click="clearFilter1" width="90%">
                クリア
              </v-btn>
            </v-col>
            <v-col dense cols="4" justify="center">
              <p>フィルタ</p>
            </v-col>
            <v-col dense cols="4" justify="center">
              <v-btn color="accent" class="black--text" @click="doFilter1" width="90%">
                適用
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex justify-center flex-column">
          <v-row dense>
            <v-col cols="12">
              <!-- グループフィルタ -->
              <v-select
                v-model="groupFilterValue"
                label="グループ指定"
                :items="groupList"
                item-text="groupName"
                item-value="filterItem"
                outlined
                dense
                clearable
                multiple
                chips
                deletable-chips
                hide-details="auto"
                background-color="white"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <!-- 出欠区分フィルタ -->
              <v-select
                v-model="syukketuKbnFilterValue"
                label="出欠区分指定"
                :items="syukketuKbnList"
                outlined
                dense
                clearable
                multiple
                chips
                deletable-chips
                hide-details="auto"
                background-color="white"
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-select
                label="並び順"
                :items="sortKbnList"
                item-text="text"
                item-value="value"
                v-model="sortKbn"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { httpsCallable, getFunctions } from "firebase/functions";
import { format, parse } from 'date-fns';
import { ja } from 'date-fns/locale';
import Enumerable from "linq";
export default {
  props: ['param_yyyymmdd'],
  watch: {
    yyyymmdd: async function(newVal, oldVal) {
      // 本日判定
      var today = format(new Date(), 'yyyy-MM-dd', { locale: ja });
      console.log(today, newVal, oldVal)
      this.isTodayFlg = (today == newVal)?true:false;
      await this.reload();
    },
    // ソート順変更時のソート
    sortKbn: async function(newVal, oldVal) {
      console.log(newVal, oldVal);
      if(newVal == "1") {
        this.members = Enumerable.from(this.membersBase).orderBy((x) => { return x.orderNoKBKGCS }).toArray();
      } else if(newVal == "2") {
        this.members = Enumerable.from(this.membersBase).orderByDescending((x) => { return x.parentUpdDateTime }).toArray();
      } else if(newVal == "3") {
        this.members = Enumerable.from(this.membersBase).orderByDescending((x) => { return x.orderNoKBKGCS }).toArray();
      } else if(newVal == "4") {
        this.members = Enumerable.from(this.membersBase).orderBy((x) => { return x.parentUpdDateTime }).toArray();
      }
      this.$store.dispatch('setSortCondition_AttendanceConfirm', newVal);
    },
    groupFilterValue: function(newVal) {this.$store.dispatch('setSearchConditionSyukketuGroup', newVal)},
  },
  data() {
    return {
      // フィルタ用
      filterDialog1: false,
      isFilterFlg1: false,

      yyyymmdd: "",
      yyyymmddDisp: "",
      isTodayFlg: false,
      headers: [
        { text: "生徒氏名 連絡日時", value: "name", class: "hidden-xs-only", width: "20%" },
        { text: "課程/部/科 学年/学級/出席番号", value: "filterItem", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "20%", filter: this.groupFilter },
        { text: "通用門通過", value: "gatePassageTime", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "8%" },
        { text: "出欠区分", value: "syukketuKbn", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "60px", filter: this.syukketuKbnFilter },
        { text: "備考", value: "biko", class: "hidden-xs-only", cellClass: "hidden-xs-only" },
        { text: "出欠確認", value: "readSchoolFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "5%" },
        { text: "", value: "action0", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "5%" },
        { text: "", value: "action1", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "5%" },
        { text: "出欠確定", value: "approvalFlg", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "5%" },
        { text: "", value: "action2", class: "hidden-xs-only", cellClass: "hidden-xs-only", width: "5%" },
      ],
      // 検索直後のデータ
      membersBase: [],
      // ソートした後のデータ
      members: [],
      // ソート用区分
      sortKbn: "1",
      sortKbnList: [
        {value:"1", text:"学年/学級/出席番号(昇順)"},
        {value:"2", text:"保護者連絡日時(降順)"},
        {value:"3", text:"学年/学級/出席番号(降順)"},
        {value:"4", text:"保護者連絡日時(昇順)"},
      ],
      isListLoding: false,
      language:{
        language: 'Japanese',
        months: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        monthsAbbr: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
        days: ['日', '月', '火', '水', '木', '金', '土'],
        yearSuffix: '年',
        ymd: true,
      },
      groupFilterValue: [],
      groupList: [],
      syukketuKbnFilterValue: [ 1, 5 ],
      syukketuKbnList: [
        { text: "出席", value: 0 },
        { text: "欠席", value: 1 },
        { text: "遅刻", value: 5 }
      ],

      // 出欠確定用
      approvalStudentId: "",
      approvalStudentNm: "",
      attendanceApprovalDialog: false,
      selectMain: [],
      selectedMain: "", //初期値
      selectSub: [],
      selectSubFilter: [],
      approvalKbn: "", //初期値
      approvalText: "", //初期値

      // 早退予定者数
      cancelCount: 0,

      tableHeight: 0,  // v-data-table 高さ

      intervalid1:"",
      lastReloadTime:"",
    };
  },
  // 初期表示時データ読み込み
  async created() {
    var date = parse(this.param_yyyymmdd, 'yyyy-MM-dd', new Date());
    this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
    this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });

    this.sortKbn = this.$store.state.sortCondition_AttendanceConfirm;

    const functions = getFunctions(this.$firebase);
    functions.region = "asia-northeast1";
    const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
    schoolAidHomeLogined({funcName: "getSyukketuGroup"}).then((ret) => {
      this.groupList = ret.data;
      this.groupFilterValue = this.$store.state.searchConditionSyukketuGroup;
    });

    await this.reload();

    // 定期更新の設定
    this.intervalid1 = setInterval(() => {
      this.reload();
    }, 900000);
  },
  beforeDestroy () {
    // 定期更新の設定破棄
    clearInterval(this.intervalid1)
  },
  methods: {
    // グループフィルタ
    groupFilter(value) {
      if (this.groupFilterValue.length == 0) {
        return true;
      }
      return this.groupFilterValue.includes(value.toString());
    },
    // 出欠区分フィルタ
    syukketuKbnFilter(value) {
      if (this.syukketuKbnFilterValue.length == 0) {
        return true;
      }
      console.log(this.syukketuKbnFilterValue, value);
      return this.syukketuKbnFilterValue.includes(value);
    },
    // データピッカー開閉
    calendar() {
        if(!this.$refs.picker.isOpen) {
            this.$refs.picker.$el.querySelector("input").focus();
            this.$refs.picker.showCalendar();
        } else {
            this.$refs.picker.close();
        }
    },
    // データピッカー日付選択
    dateSelected(selectedDate) {
        this.yyyymmdd = format(selectedDate, 'yyyy-MM-dd', { locale: ja });
        this.yyyymmddDisp = format(selectedDate, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 前の日へボタン
    toBack() {
      var date = parse(this.yyyymmdd, 'yyyy-MM-dd', new Date());
      date.setDate(date.getDate() - 1);
      this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 次の日へボタン
    toForward() {
      var date = parse(this.yyyymmdd, 'yyyy-MM-dd', new Date());
      date.setDate(date.getDate() + 1);
      this.yyyymmdd = format(date, 'yyyy-MM-dd', { locale: ja });
      this.yyyymmddDisp = format(date, 'yyyy年M月d日(E)', { locale: ja });
    },
    // 出欠訂正
    onClickEditItem(item) {
      this.$router.push({
        name: "AttendanceEdit",
        params: {
          studentId:item.loginId,
          studentName:item.name,
          param_yyyymmdd: this.yyyymmdd,
          beforeName:"AttendanceConfirm",
        }
      });
    },
    // 出欠確定
    async onClickApprovalItem(item) {
      // ローディング開始
      this.$root.loading = true;
      this.approvalStudentId = item.loginId;
      this.approvalStudentNm = item.name;
      this.attendanceApprovalDialog = true;

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      const data = {
        funcName: "loadAttendanceApproval",
        yyyymmdd: this.yyyymmdd,
        studentId: this.approvalStudentId
      };

      await schoolAidHomeLogined(data).then((res) => {
        this.$root.loading = false;
        this.selectMain = [{code: "", name: "", orderNo: "", yobi1: ""}].concat(res.data.selectMain);
        this.selectSub = res.data.selectSub;
        this.selectedMain = res.data.approvalKbn.substring(0, 2);
        if(res.data.approvalKbn != ""){
          this.selectSubFilter = Enumerable.from(this.selectSub).where(i => i.code.substring(0, 2) == this.selectedMain).toArray();
        }

        this.approvalKbn = res.data.approvalKbn;
        this.approvalText = res.data.approvalText;

      }).catch((e) =>{
        this.$root.loading = false;
        console.log(e.message);
        this.error = e.message;
      });
    },
    // 出欠情報設定保存
    async syukketuApprovalSave() {
      // ローディング開始
      this.$root.loading = true;
      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions,"schoolAidHomeLogined");
      var data = {
        funcName: "saveAttendanceApproval",
        yyyymmdd: this.yyyymmdd,
        studentId: this.approvalStudentId,
        approvalKbn: this.approvalKbn,
        approvalText: this.approvalText,
      };

      await schoolAidHomeLogined(data).then((res) => {
        this.$root.loading = false;
        console.log(res.data);
        if (res.data) {
          alert("登録しました");

          // reloadせずにapprovalFlgの画面更新
          this.members.forEach((doc) => {
            if (doc.loginId === this.approvalStudentId) {
              doc.approvalFlg = res.data.approvalFlg;
            }
          });
        }
      }).catch((e) =>{
        this.$root.loading = false;
        console.log(e.message);
        this.error = e.message;
      });

      this.attendanceApprovalDialog = false;
    },
    async changeMain() {
      console.log('changeMain');
      this.approvalKbn = "";
      this.approvalText = "";
      this.selectSubFilter = Enumerable.from(this.selectSub).where(i => i.code.substring(0, 2) == this.selectedMain).toArray();
    },
    async changeSub() {
      console.log('changeSub');
      if(!this.approvalKbn.endsWith('99')){
        this.approvalText = "";
      }
    },
    // ページ再読込
    async reload() {
      this.members = [];

      var param = {
        funcName: "loadAttendanceConfirm",
        yyyymmdd: this.yyyymmdd
      }

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      this.isListLoding = true;
      await schoolAidHomeLogined(param).then((syukketus) => {
        var ret = [];
        syukketus.data.forEach((syukketu) => {
          // 出欠区分の日本語変換
          let syukketuKbn = syukketu.syukketuKbn;
          let syukketuNm = "";
          if (syukketuKbn === 0) {
            syukketuNm = "";
          } else if (syukketuKbn === 1) {
            syukketuNm = "欠席";
          } else if (syukketuKbn === 5) {
            syukketuNm = "遅刻";
          } else if (syukketuKbn === 6) {
            syukketuNm = "早退予定";
          }
          let createUserKbn = syukketu.createUserKbn;
          let createUserFlg = false;
          if(createUserKbn == "8"){
            createUserFlg = true;
          }

          var row = {
            mailAddressAuthFlg: syukketu.mailAddressAuthFlg,
            name : syukketu.name,
            mailAddressAuthFlgParent: syukketu.mailAddressAuthFlgParent,
            parentName : syukketu.parentName,
            filterItem : syukketu.filterItem,
            kateiNm: syukketu.kateiNm,
            buNm: syukketu.buNm,
            kaNm: syukketu.kaNm,
            gakunen: syukketu.gakunen,
            gakkyu: syukketu.gakkyu,
            syussekiNo: syukketu.syussekiNo,
            syukketuKbn: syukketu.syukketuKbn,
            syukketuNm: syukketuNm,
            biko: syukketu.biko,
            createUserNm: syukketu.createUserNm,
            createUserFlg: createUserFlg,
            readSchoolFlg: (syukketu.readSchoolFlg == true) ? true : false,
            approvalFlg: (syukketu.approvalFlg == true) ? true : false,
            loginId: syukketu.loginId,
            pastLockFlg: syukketu.pastLockFlg,
            gatePassageTime: syukketu.gatePassageTime,
            parentUpdDate: syukketu.parentUpdDate,
            parentUpdDateTime: syukketu.parentUpdDateTime,
            orderNoKBKGCS: syukketu.orderNoKBKGCS,
          };
          ret.push(row);
        });
        this.membersBase = ret;
        if(this.sortKbn == "1") {
          this.members = Enumerable.from(ret).orderBy((x) => { return x.orderNoKBKGCS }).toArray();
        } else if(this.sortKbn == "2") {
          this.members = Enumerable.from(ret).orderByDescending((x) => { return x.parentUpdDateTime }).toArray();
        } else if(this.sortKbn == "3") {
          this.members = Enumerable.from(ret).orderByDescending((x) => { return x.orderNoKBKGCS }).toArray();
        } else if(this.sortKbn == "4") {
          this.members = Enumerable.from(ret).orderBy((x) => { return x.parentUpdDateTime }).toArray();
        }

        this.isListLoding = false;
      }).catch((e) =>{
        console.log(e.message);
        this.error = e.message;
        this.$root.loading = false;
      });

      // 出席数のカウント用
      var syussekiList = Enumerable.from(this.membersBase).where(i => i.syukketuKbn == 0).toArray();
      this.cancelCount = syussekiList.length;
      this.lastReloadTime = format(new Date(), "HH時mm分ss秒", { locale: ja });
    },
    // 出欠確認ボタン制御
    lockConfirmButton(item) {
      // 学期開始日前であればロック
      if(item.pastLockFlg)return true;
      // 当日でなければロック
      if(!this.isTodayFlg)return true;
      // 確認済であればロック
      if(item.readSchoolFlg)return true;
      return false;
    },
    // 出欠確認ボタン押下時制御
    async onClickReadItem(item) {
      var param = {
        funcName: "updateReadSchoolFlg",
        yyyymmdd: this.yyyymmdd,
        studentId: item.loginId,
      }

      const functions = getFunctions(this.$firebase);
      functions.region = "asia-northeast1";
      const schoolAidHomeLogined = httpsCallable(functions, "schoolAidHomeLogined");
      await schoolAidHomeLogined(param).then((ret) => {
        if(ret.data == "success"){
          item.readSchoolFlg = true;
        }
      });
    },
    // データテーブルの高さ制御
    updHeight(){
      this.tableHeight = parseInt(window.innerHeight * 0.6);
    },

    // 検索モーダル用 =================================================================================
    doFilter1() {
      this.isFilterFlg1 = true;
      this.filterDialog1 = false;
  },
    clearFilter1(){
      this.groupFilterValue = "";
      this.syukketuKbnFilterValue = [ 1, 5 ];

      this.isFilterFlg1 = false;
      this.filterDialog1 = false;
    },
  },
};
</script>

<style scoped>
.calender-text-box-wrapper{
}
.calender-text-box {
    display: none;
}
.vdp-datepicker__calendar {
    width: 100% !important;
}
</style>